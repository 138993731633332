import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";

function Contact() {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const contactSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!name) newErrors.name = "Enter a name";
    if (!mail) newErrors.mail = "Enter an email";
    if (!phone) newErrors.phone = "Enter a phone number";
    if (!message) newErrors.message = "Enter a message";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      alert("Send Success!");
      setName("");
      setMail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>お問い合わせ</h1>
          </div>
          <div className="contact_first_txt">
            <p>
              その他、当ショップへのご意見・ご感想・お問い合わせは、下記よりお願いいたします。
            </p>
            <p>
              自動返信メールが届かない場合は、何らかの原因で当ショップへお問い合わせが
              <br />
              届いていない可能性がございます。
              <br />
              お手数ですが今一度メールアドレスをお確かめの上、再度ご操作ください。
            </p>
            <p>
              ◯お問い合わせ窓口に関して
              <br />
              【定 休 日】：日曜日
              <br />
              【営業時間】：10時〜16時
              <br />
              ※上記時間外でお受けしたお問い合わせは、翌営業時間より順次ご返信差し上げます。
            </p>
            <br />
            <p className="allow-request-box" style={{ color: "#ff0000" }}>
              <font _mstmutation="1">
                <span className="pc-message" _mstmutation="1">
                  -----【
                </span>
                <span className="caption" _mstmutation="1">
                  メールアドレス指定解除のお願い
                </span>
                <span className="pc-message" _mstmutation="1">
                  】-----
                  <br />
                  <br />
                </span>
                セキュリティ設定により、メールが届かない場合がございます。
                <br _mstmutation="1" />
                以下のメールアドレスからの指定受信設定をお願いいたします。
                <br _mstmutation="1" />
                <br _mstmutation="1" />
                <span className="pc-message" _mstmutation="1">
                  指定メールアドレス　：【　
                </span>
                <span style={{ fontSize: "1.5em" }} _mstmutation="1">
                  support@laeetyp.live
                </span>
                <span className="pc-message" _mstmutation="1">
                  　】
                </span>
              </font>
            </p>
          </div>
          <div className="contact-form form-vertical">
            <form
              id="ContactForm"
              acceptCharset="UTF-8"
              className="contact-form"
              onSubmit={contactSubmit}
            >
              <div className="grid grid--half-gutters">
                <div className="grid__item medium-up--one-half">
                  <label htmlFor="ContactForm-name">お名前 （必須）</label>
                  <input
                    type="text"
                    id="ContactForm-name"
                    name="contact[名前]"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="grid__item medium-up--one-half">
                  <label htmlFor="ContactForm-email">
                    メールアドレス （必須）
                  </label>
                  <input
                    type="email"
                    id="ContactForm-email"
                    name="contact[email]"
                    autoCorrect="off"
                    autoCapitalize="off"
                    required
                    aria-required="true"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
              </div>
              <div className="grid__item medium-up--one-half">
                <label htmlFor="ContactForm-phone">
                  お問い合わせタイトル （必須）
                </label>
                <input
                  type="tel"
                  id="ContactForm-phone"
                  name="contact[電話番号]"
                  pattern="[0-9\-]*"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="grid__item medium-up--one-half">
                <label htmlFor="ContactForm-message">
                  お問い合わせ内容 （必須）
                  <br />
                  <small>ご入力は「全角」でお願いいたします。</small>
                  <br />
                  <small>
                    【内容】【連絡先（自宅／携帯）】をご入力ください。
                  </small>
                </label>
                <textarea
                  rows="10"
                  id="ContactForm-message"
                  name="contact[メッセージ]"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="商品ID：1000005288"
                ></textarea>
              </div>
              <div
                className="grid__item medium-up--one-half"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <input type="submit" className="btn" value="送信" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
