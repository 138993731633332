const productData = [
  {
    id: 1,
    name: "ジャックバニー（Jack Bunny!!）JB ボタニカル柄◆半袖ポロシャツ",
    main: "ジャックバニー",
    description: [
      "ボタニカル柄が爽やかな印象",
      "シックなボタニカル柄がウェア下部分を飾る、カジュアルで印象的なデザインの半袖ポロシャツ。胸元のロゴワッペンがメリハリのあるアクセントに。",
      "ポリエステル100％のさらりとした触り心地。換気性に優れた素材「CoolPass」を使用しています。肌当たりは柔らかく、涼しげで爽やかな着心地を実現。暑い日のゴルフプレーをより快適にしてくれます。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "2013年春にスタートしたカジュアルラインブランド。ファミリーで楽しめるお洒落なゴルフウェアを提案。",
      "「GOLF IS LOVE」「ゴルフを楽しく、はじめよう。」をコンセプトに、年齢に左右されず、愛する誰かと愛するゴルフをいつまでも長く楽しめるよう、ファッションの面からサポート！",
      "ブランド名にちなんだウサギモチーフのアイテムが特徴 。",
    ],
    price: "2640",
    preprice: "13200",
    off: "80%",
    sizeImg: "/curu/size01.png",
    size: ["4(M)", "5(L)", "6(XL)"],
    color: ["ピンク"],
    brand: "ジャックバニー（Jack Bunny!!）",
    catagory: "	半袖ポロシャツ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "	2621160241",
    images: [
      "/curu/1001.jpg",
      "/curu/1001b.jpg",
      "/curu/1001c.jpg",
      "/curu/1001d.jpg",
      "/curu/1001e.jpg",
    ],
  },
  {
    id: 2,
    name: "ジャックバニー（Jack Bunny!!）JB ジオメトリック半袖ポロシャツ",
    main: "ジャックバニー",
    description: [
      "個性際立つ、矢印のジオメトリック総柄デザイン",
      "矢印がデザインされたジオメトリック総柄が、個性的で目を惹くルックスの半袖ポロシャツ。左胸元とバックネック部分にはシリコン素材のロゴが施されており、総柄の中にもメリハリを効かせたアクセントになっています。各カラー共に存在感のある、ホワイト、イエロー、ブルーの3色展開をご用意。",
      "やや薄手の鹿の子生地。襟と袖口はリブ素材。さらっとした肌触りの通気性の良い生地感なので、汗をかいてもべた付かずに快適に着用していただけます。ほど良いストレッチ性を兼ね備えており動きやすく、アクティブな動作の多いゴルフ時にピッタリのアイテム◎",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "2013年春にスタートしたカジュアルラインブランド。ファミリーで楽しめるお洒落なゴルフウェアを提案。",
      "「GOLF IS LOVE」「ゴルフを楽しく、はじめよう。」をコンセプトに、年齢に左右されず、愛する誰かと愛するゴルフをいつまでも長く楽しめるよう、ファッションの面からサポート！",
      "ブランド名にちなんだウサギモチーフのアイテムが特徴 。",
    ],
    price: "5005",
    preprice: "14300",
    off: "65%",
    sizeImg: "/curu/size02.png",
    size: ["4", "5", "6"],
    color: ["イエロー"],
    brand: "ジャックバニー（Jack Bunny!!）",
    catagory: "		半袖ポロシャツ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "2623260823",
    images: [
      "/curu/1002.jpg",
      "/curu/1002a.jpg",
      "/curu/1002b.jpg",
      "/curu/1002c.jpg",
      "/curu/1002d.jpg",
      "/curu/1002e.jpg",
      "/curu/1002f.jpg",
      "/curu/1002g.jpg",
      "/curu/1002h.jpg",
      "/curu/1002i.jpg",
      "/curu/1002j.jpg",
    ],
  },
  {
    id: 3,
    name: "OT 【HIGUMA別注】THE_L.A.MART_BACKロゴUVモックネックカットソー",
    main: "ヒグマゴルフストア",
    description: [
      "HIGUMA×The L.A.MART",
      "西海岸ロサンジェルスをイメージしたカジュアルウェアブランド「The L.A. Mart」とのコラボ商品。",
      "着回しやすいTシャツのようなカジュアルデザインで様々な着こなしが可能。左袖に「HIGUMA GOLF STORE」のロゴプリント。背中のロゴプリントとヤシの木がポイントの半袖モックネックカットソー。ゴルフだけでなく、日常使いにも活躍しそう。ホワイトとブラックの二色をご用意しました。",
      "上品で高級感漂う、さらさらでややつるっとしたストレッチ生地を使用。UVカット・吸水速乾・耐久性・通気性すべてを兼ね揃えた高機能素材で、春から夏にかけてのラウンドをサポートします。シルエットはややゆったりとしたオーバーサイズで、こなれたリラックス感を演出。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
    ],
    price: "3080",
    preprice: "8800",
    off: "65%",
    sizeImg: "/curu/size03.png",
    size: ["S", "M", "L"],
    color: ["ホワイト", "ブラック"],
    brand: "HIGUMA GOLF STORE",
    catagory: "半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "323101",
    images: [
      "/curu/1003.jpg",
      "/curu/1003a.jpg",
      "/curu/1003b.jpg",
      "/curu/1003c.jpg",
      "/curu/1003d.jpg",
      "/curu/1003e.jpg",
      "/curu/1003f.jpg",
      "/curu/1003g.jpg",
      "/curu/1003h.jpg",
      "/curu/1003i.jpg",
      "/curu/1003j.jpg",
      "/curu/1003k.jpg",
      "/curu/1003l.jpg",
      "/curu/1003m.jpg",
    ],
  },
  {
    id: 4,
    name: "OT 【HIGUMA別注】THE_L.A.MART_BACKロゴUVモックネックカットソー",
    main: "ヒグマゴルフストア",
    description: [
      "HIGUMA×The L.A.MART",
      "西海岸ロサンジェルスをイメージしたカジュアルウェアブランド「The L.A. Mart」とのコラボ商品。",
      "着回しやすいTシャツのようなカジュアルデザインで様々な着こなしが可能。左袖に「HIGUMA GOLF STORE」のロゴプリント。背中のロゴプリントとヤシの木がポイントの半袖モックネックカットソー。ゴルフだけでなく、日常使いにも活躍しそう。ホワイトとブラックの二色をご用意しました。",
      "上品で高級感漂う、さらさらでややつるっとしたストレッチ生地を使用。UVカット・吸水速乾・耐久性・通気性すべてを兼ね揃えた高機能素材で、春から夏にかけてのラウンドをサポートします。シルエットはややゆったりとしたオーバーサイズで、こなれたリラックス感を演出。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
    ],
    price: "3080",
    preprice: "8800",
    off: "65%",
    sizeImg: "/curu/size03.png",
    size: ["S", "M", "L"],
    color: ["ホワイト", "ブラック"],
    brand: "HIGUMA GOLF STORE",
    catagory: "	半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "323101",
    images: [
      "/curu/1003b.jpg",
      "/curu/1003.jpg",
      "/curu/1003a.jpg",
      "/curu/1003c.jpg",
      "/curu/1003d.jpg",
      "/curu/1003e.jpg",
      "/curu/1003f.jpg",
      "/curu/1003g.jpg",
      "/curu/1003h.jpg",
      "/curu/1003i.jpg",
      "/curu/1003j.jpg",
      "/curu/1003k.jpg",
      "/curu/1003l.jpg",
      "/curu/1003m.jpg",
    ],
  },
  {
    id: 5,
    name: "OT 【HIGUMA別注】THE_L.A.MARTロゴプリントUVモックネックカットソー",
    main: "ヒグマゴルフストア",
    description: [
      "HIGUMA×The L.A.MARTコラボアイテム！",
      "西海岸ロサンジェルスをイメージしたカジュアルウェアブランド「The L.A. Mart」とのコラボ商品。",
      "着回しやすいTシャツのようなカジュアルデザインで様々な着こなしが可能。左袖に「HIGUMA GOLF STORE」のロゴプリント。バックプリントも存在感を放つ半袖モックネックカットソー。ネイビーとベージュのベーシックなカラー展開で、ボトムスを選ばないのも優秀◎",
      "ややツルっとしたサラサラ感触の、上品な高級感ある生地を使用。少しゆったりとしたオーバーサイズのシルエットのため、Tシャツのように1枚で気軽に着用が可能です。UVカット・耐久性・通気性・吸水速乾機能を備え、汗のべたつきや紫外線を気にせず、プレーに集中できます。高ストレッチ性で、ゴルフスイングなどの動作もスムーズ！",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
    ],
    price: "3080",
    preprice: "8800",
    off: "65%",
    sizeImg: "/curu/size05.png",
    size: ["S", "M", "L"],
    color: ["ネイビー", "ベージュ"],
    brand: "	HIGUMA GOLF STORE",
    catagory: "半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "323100",
    images: [
      "/curu/1005.jpg",
      "/curu/1005a.jpg",
      "/curu/1005b.jpg",
      "/curu/1005c.jpg",
      "/curu/1005d.jpg",
      "/curu/1005e.jpg",
      "/curu/1005f.jpg",
      "/curu/1005g.jpg",
      "/curu/1005h.jpg",
      "/curu/1005i.jpg",
      "/curu/1005j.jpg",
      "/curu/1005k.jpg",
      "/curu/1005l.jpg",
      "/curu/1005m.jpg",
    ],
  },
  {
    id: 6,
    name: "OT 【HIGUMA別注】THE_L.A.MARTロゴプリントUVモックネックカットソー",
    main: "ヒグマゴルフストア",
    description: [
      "HIGUMA×The L.A.MARTコラボアイテム！",
      "西海岸ロサンジェルスをイメージしたカジュアルウェアブランド「The L.A. Mart」とのコラボ商品。",
      "着回しやすいTシャツのようなカジュアルデザインで様々な着こなしが可能。左袖に「HIGUMA GOLF STORE」のロゴプリント。バックプリントも存在感を放つ半袖モックネックカットソー。ネイビーとベージュのベーシックなカラー展開で、ボトムスを選ばないのも優秀◎",
      "ややツルっとしたサラサラ感触の、上品な高級感ある生地を使用。少しゆったりとしたオーバーサイズのシルエットのため、Tシャツのように1枚で気軽に着用が可能です。UVカット・耐久性・通気性・吸水速乾機能を備え、汗のべたつきや紫外線を気にせず、プレーに集中できます。高ストレッチ性で、ゴルフスイングなどの動作もスムーズ！",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
    ],
    price: "3080",
    preprice: "8800",
    off: "65%",
    sizeImg: "/curu/size06.png",
    size: ["S", "M", "L"],
    color: ["ネイビー", "ベージュ"],
    brand: "	HIGUMA GOLF STORE",
    catagory: "	半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "323100",
    images: [
      "/curu/1005c.jpg",
      "/curu/1005.jpg",
      "/curu/1005a.jpg",
      "/curu/1005b.jpg",
      "/curu/1005d.jpg",
      "/curu/1005e.jpg",
      "/curu/1005f.jpg",
      "/curu/1005g.jpg",
      "/curu/1005h.jpg",
      "/curu/1005i.jpg",
      "/curu/1005j.jpg",
      "/curu/1005k.jpg",
      "/curu/1005l.jpg",
      "/curu/1005m.jpg",
    ],
  },
  {
    id: 7,
    name: "ホーンガーメント（HORN G.M.T）HO [HAWAIIAN航空]選べるデザイン半袖ポロシャツ",
    main: "ホーンガーメント",
    description: [
      "航空機をモチーフとしたオシャレな選べる2つのデザイン",
      "ハワイアン航空とのコラボ商品である半袖ポロシャツ。華やかで可愛らしいお花のようなプロペラ柄と、爽やかで素敵な海と空の景色柄の2つのデザインからお選びいただけます。左胸元にはロゴ刺繍、随所にはロゴタグが施されておりアクセントに。2つのデザインで異なる、右胸元に配されたオシャレなロゴプリントにも注目です。",
      "やや薄手のポリエステル100%生地。襟と袖口はきちんと感のあるリブ素材。さらさら、ツルツルした肌触りで着心地が良いので、一枚で快適に着用していただけます。高い伸縮性を兼ね備えており動きやすく、アクティブな動作の多いゴルフ時にピッタリのアイテム◎ 裾サイドにはスリットが施されています。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "2006年のブランド設立から「余裕ある大人のライフスタイルカジュアル」を提案してきた ｢HORN GARMENT/ホーンガーメント｣",
      "LUXARY SURF/ラグジャリーサーフ”をテーマにSURF、RELAX、ENJOYをモットーに大人のサーフスタイルを展開しています。 サーフ＆ターフなゴルフウェア＆グッズにも注目。",
    ],
    price: "7260",
    preprice: "24200",
    off: "80%",
    sizeImg: "/curu/size07.png",
    size: ["M", "L", "XL", "XXL"],
    color: ["ベージュ×プロペラ柄", "パープル×景色柄"],
    brand: "ホーンガーメント（HORN G.M.T）",
    catagory: "半袖ポロシャツ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "HVM3AAP01",
    images: [
      "/curu/1007.jpg",
      "/curu/1007a.jpg",
      "/curu/1007b.jpg",
      "/curu/1007c.jpg",
      "/curu/1007d.jpg",
      "/curu/1007e.jpg",
      "/curu/1007f.jpg",
      "/curu/1007g.jpg",
      "/curu/1007h.jpg",
      "/curu/1007i.jpg",
      "/curu/1007j.jpg",
    ],
  },
  {
    id: 8,
    name: "ホーンガーメント（HORN G.M.T）HO [HAWAIIAN航空]エアライン半袖ポロシャツ",
    main: "ホーンガーメント",
    description: [
      "リゾートトラベルを感じさせる優雅なバックプリントに注目",
      "ハワイアン航空とのコラボ商品である半袖ポロシャツ。バックには夕暮れカラーの空飛ぶ航空機プリントが施されており、リゾート感を醸し出すオシャレなポイントになっています。目を惹くアクセントとして胸元にはロゴ刺繍、フロント裾付近にはロゴタグを配置。前立て部分には、サングラスを引っ掛けることができる便利なタグが施されています。",
      "やや薄手のポリエステル100%生地。さらりとした鹿の子調の生地になっているので、一枚で快適に着用していただけます。ゴルフ時の動作を妨げない適度なストレッチ性を持ち合わせており、動きやすさ◎ 裾サイドにはスリット入り。日本製なので品質面でも安心です。ホワイトはやや透け感があり。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "2006年のブランド設立から「余裕ある大人のライフスタイルカジュアル」を提案してきた ｢HORN GARMENT/ホーンガーメント｣",
      "LUXARY SURF/ラグジャリーサーフ”をテーマにSURF、RELAX、ENJOYをモットーに大人のサーフスタイルを展開しています。 サーフ＆ターフなゴルフウェア＆グッズにも注目。",
    ],
    price: "7315",
    preprice: "20900",
    off: "65%",
    sizeImg: "/curu/size08.png",
    size: ["46(M)", "48(L)", "50(XL)", "52(XXL)"],
    color: ["ホワイト"],
    brand: "	ホーンガーメント（HORN G.M.T）",
    catagory: "	半袖ポロシャツ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "HVM3AAP02",
    images: [
      "/curu/1008.jpg",
      "/curu/1008a.jpg",
      "/curu/1008b.jpg",
      "/curu/1008c.jpg",
      "/curu/1008d.jpg",
      "/curu/1008e.jpg",
      "/curu/1008f.jpg",
      "/curu/1008g.jpg",
    ],
  },
  {
    id: 9,
    name: "グラビスゴルフ（Gravis GOLF）GV BACKロゴモックネック半袖カットソー",
    main: "ホーンガーメント",
    description: [
      "サマーゴルフにぴったりの接触冷感ナイロンを使用",
      "接触冷感ナイロンを使用したソリッドなモックネック。フロントにGマークのプリント、バックにメッセージプリントを大きくデザインしています。",
      "ハイゲージのナイロントリコット素材を採用。触れた瞬間に伝わる冷たさがサマーゴルフにぴったりです。ゴルフシーン以外にも、プラクティスシーンやタウンユースでの着用もおすすめです。",
      "・裏返しして洗濯用ネットをご使用下さい。",
      "・アイロン時はあて布をして付属部分のアイロンはお避け下さい。",
      "・プリント部分への直接のアイロンはお避け下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
    ],
    price: "8855",
    preprice: "12100",
    off: "65%",
    sizeImg: "/curu/size09.png",
    size: ["S", "M", "L", "XL"],
    color: ["ネイビー"],
    brand: "グラビスゴルフ（Gravis GOLF）",
    catagory: "	半袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "GGM3AAA02",
    images: [
      "/curu/1009.jpg",
      "/curu/1009a.jpg",
      "/curu/1009b.jpg",
      "/curu/1009c.jpg",
      "/curu/1009d.jpg",
      "/curu/1009e.jpg",
      "/curu/1009f.jpg",
      "/curu/1009g.jpg",
      "/curu/1009h.jpg",
      "/curu/1009i.jpg",
      "/curu/1009j.jpg",
      "/curu/1009k.jpg",
    ],
  },
  {
    id: 10,
    name: "パーリーゲイツ（PEARLY GATES）PG ジャガードロゴライン市松柄ニット",
    main: "パーリーゲイツ",
    description: [
      "・やや薄手のサラッとしたポリニット素材",
      "・立体感のある編地がお洒落",
      "・ジャガードロゴラインがアクセントに",
      "・左胸と背肩にロゴワッペンを配置",
      "※この商品は、糸及び素材の特性上、引っかけ・ひっかかりにより糸がとびだしたり、ひきつれが生じやすい性質をもっています。表面の粗いものとの摩擦やひっかかりにご注意ください。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "パーリーゲイツ（PEARLY GATES）はあらゆる枠組みを越えオシャレなゴルフウェアを発信。鮮やかな配色で、個性的なコーディネートが特徴的の大人気ゴルフウェアブランド。",
      "スタイリッシュでカジュアルな中に、申し分ない機能性を加え、あらゆる枠組みを超越した個性的なゴルフウェアを提案し続ける。 ゴルフプレーヤーなら知らない人はいないほど、ゴルフファンから絶大な人気を誇る'パーリーゲイツ'から今後も目がはなせない！",
    ],
    price: "33000",
    sizeImg: "/curu/size010.png",
    size: ["4", "5", "6"],
    color: ["ダークネイビー", "オフホワイト"],
    brand: "パーリーゲイツ（PEARLY GATES）",
    catagory: "	セーター",
    shipDate: "	当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "0534270901",
    images: [
      "/curu/10010m.jpg",
      "/curu/10010.jpg",
      "/curu/10010a.jpg",
      "/curu/10010b.jpg",
      "/curu/10010c.jpg",
      "/curu/10010d.jpg",
      "/curu/10010e.jpg",
      "/curu/10010f.jpg",
      "/curu/10010g.jpg",
      "/curu/10010h.jpg",
      "/curu/10010i.jpg",
      "/curu/10010j.jpg",
      "/curu/10010k.jpg",
      "/curu/10010l.jpg",
    ],
  },
  {
    id: 11,
    name: "ニューエラ（NEW ERA）NE パネル切り替えハーフジッププルオーバー(オリーブ)",
    main: "ニューエラ",
    description: [
      "・程よいハリ感ともっちり感のあるスウェット素材",
      "・パネル切り替えデザインがスポーティーカジュアル",
      "・フロントに大きめロゴ刺繍、左袖と首後ろにもロゴ刺繍を配置",
      "・ポケットは2つ",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ニューヨーク州のバッファローで、紳士用カジュアルキャップやユニフォーム用キャップの製造からスタートした「NEW ERA(ニューエラ)」。",
      "世界最高峰と言われ、老舗となった今でも常に品質にこだわった「品質第一」主義を貫きとおし、数多くのファンを魅了し続けている。 中でもベースボールキャップは超有名。さまざまなスポーツやファッションに合わせ、数多くのアイテムを生み続けた「NEW ERA」は、今やタウンでも見かけるファッションアイテム。",
    ],
    price: "13200",
    sizeImg: "/curu/size011.png",
    size: ["M", "L", "XL"],
    color: ["オリーブ"],
    brand: "ニューエラ（NEW ERA）",
    catagory: "	プルオーバー・スニード",
    shipDate: "当日 or 翌営業日出荷",
    returns: " 可",
    itemNo: "14326383",
    images: [
      "/curu/10011.jpg",
      "/curu/10011a.jpg",
      "/curu/10011b.jpg",
      "/curu/10011c.jpg",
      "/curu/10011d.jpg",
      "/curu/10011e.jpg",
      "/curu/10011f.jpg",
      "/curu/10011g.jpg",
      "/curu/10011h.jpg",
      "/curu/10011i.jpg",
      "/curu/10011j.jpg",
    ],
  },
  {
    id: 12,
    name: "ニューエラ（NEW ERA）NE ダンボール調テーパードパンツ(オリーブ)",
    main: "ニューエラ",
    description: [
      "・程よいハリ感ともっちり感のあるダンボール調素材",
      "・柔らかい生地感と軽い素材でストレスのない履き心地",
      "・バックウエストにはロゴ入りのポンチゴムを使用",
      "・左サイドと、左ふきらはぎあたりにロゴ刺繍を配置",
      "・ストレッチ◎",
      "・ポケットは4つ、ループ付き",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ニューヨーク州のバッファローで、紳士用カジュアルキャップやユニフォーム用キャップの製造からスタートした「NEW ERA」。",
      "世界最高峰と言われ、老舗となった今でも、常に品質にこだわった「品質第一」主義を貫き、数多くのファンを魅了し続けている。",
    ],
    price: "14300",
    sizeImg: "/curu/size012.png",
    size: ["M", "L", "XL"],
    color: ["オリーブ"],
    brand: "	ニューエラ（NEW ERA）",
    catagory: "		ロングパンツ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "14326380",
    images: [
      "/curu/10012.jpg",
      "/curu/10012a.jpg",
      "/curu/10012b.jpg",
      "/curu/10012c.jpg",
      "/curu/10012d.jpg",
      "/curu/10012e.jpg",
      "/curu/10012f.jpg",
      "/curu/10012g.jpg",
      "/curu/10012h.jpg",
      "/curu/10012i.jpg",
      "/curu/10012j.jpg",
      "/curu/10012k.jpg",
      "/curu/10012l.jpg",
    ],
  },
  {
    id: 13,
    name: "ニューエラ（NEW ERA）NE 【帯電防止】スリーブロゴハイネック裏微起毛カットソー(オリーブ)",
    main: "ニューエラ",
    description: [
      "・肌面は微起毛で暖かい肌触り",
      "・ソフトで肌なじみの良い素材",
      "・左袖にロゴプリントとロゴ刺繍、ネックにはぐるっとロゴプリントを配置",
      "・帯電防止加工で安心",
      "※摩擦やひっかけにご注意下さい。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ニューヨーク州のバッファローで、紳士用カジュアルキャップやユニフォーム用キャップの製造からスタートした「NEW ERA(ニューエラ)」。",
      "世界最高峰と言われ、老舗となった今でも常に品質にこだわった「品質第一」主義を貫きとおし、数多くのファンを魅了し続けている。 中でもベースボールキャップは超有名。さまざまなスポーツやファッションに合わせ、数多くのアイテムを生み続けた「NEW ERA」は、今やタウンでも見かけるファッションアイテム。",
    ],
    price: "7920",
    sizeImg: "/curu/size013.png",
    size: ["M", "L", "XL"],
    color: ["オリーブ"],
    brand: "	ニューエラ（NEW ERA）",
    catagory: "	長袖カットソー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "14326413",
    images: [
      "/curu/10013.jpg",
      "/curu/10013a.jpg",
      "/curu/10013b.jpg",
      "/curu/10013c.jpg",
      "/curu/10013d.jpg",
      "/curu/10013e.jpg",
      "/curu/10013f.jpg",
      "/curu/10013g.jpg",
      "/curu/10013h.jpg",
      "/curu/10013i.jpg",
    ],
  },
  {
    id: 14,
    name: "ニューバランスゴルフ（New Balance golf）NB 裏フリースロゴニットネックウォーマー",
    main: "ニューバランスゴルフ",
    description: [
      "・しっとりソフトなニット素材",
      "・フロントには大きめのロゴデザインがあしらわれている",
      "・ストレッチ性◎",
      "・裏面はなめらかなフリース素材を採用",
      "※この商品は、糸及び素材の特性上、引っかけ・ひっかかりにより糸がとびだしたり、ひきつれが生じやすい性質をもっています。表面の粗いものとの摩擦やひっかかりにご注意ください。",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "2017年春夏シーズンからスタートしたニューバランスゴルフ。機能性を軸にファッション感覚を併せ持つ「SPORT」と、ファッショントレンド要素に機能面も兼備した「METRO」の２ラインで構成。ニューバランスのコンセプトや哲学を大切に、ファッションを意識した新たなゴルフスタイルを発信しています。",
    ],
    price: "15400",
    sizeImg: "/curu/size014.png",
    size: ["フリー", "フリー", "フリー"],
    color: ["ダークグレー", "ブラック", "ホワイト"],
    brand: "	ニューバランスゴルフ（New Balance golf）",
    catagory: "ネックウォーマー・マフラー",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "0124283001",
    images: [
      "/curu/10014.jpg",
      "/curu/10014a.jpg",
      "/curu/10014b.jpg",
      "/curu/10014c.jpg",
      "/curu/10014d.jpg",
      "/curu/10014e.jpg",
      "/curu/10014f.jpg",
    ],
  },
  {
    id: 15,
    name: "ビームスゴルフ（BEAMS GOLF）【ゴルフ小物】BE 【BRIDGESTONE_GOLF】サイドロゴスパイクレスシューズ",
    main: "ビームスゴルフ",
    description: [
      "・BRIDGESTONE GOLF(ブリヂストン ゴルフ)とのコラボ商品",
      "・シンプルでクラシックなデザインが魅力",
      "・サイドにはロゴがあしらわれている",
      "・「ゼロ・スパイク・バイター」の突起形状を踏襲した高いグリップ性能を持ち、撥水性のある国産クラリーノをアッパーに用いている",
      "・モノトーンで使いやすい",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ファッション性と機能性を両立させたBEAMSのオリジナルブランド。遊び心に溢れたデザインでプレーを楽しく演出してくれるアクセサリー類がラインアップ。ゴルフがもっと楽しくなるビームスならではのゴルフスタイルは、着実にファンを増やしている。",
    ],
    price: "20900",
    sizeImg: "/curu/size015.png",
    size: [
      "23.5cm ",
      "24.0cm",
      "25.5cm",
      "26.0cm ",
      "26.5cm",
      "27.0cm ",
      "28.0cm",
    ],
    color: ["ホワイト"],
    brand: "	ビームスゴルフ（BEAMS GOLF）【ゴルフ小物】",
    catagory: "	シューズ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "81320101142",
    images: [
      "/curu/10015.jpg",
      "/curu/10015a.jpg",
      "/curu/10015b.jpg",
      "/curu/10015c.jpg",
      "/curu/10015d.jpg",
      "/curu/10015e.jpg",
      "/curu/10015f.jpg",
      "/curu/10015g.jpg",
    ],
  },
  {
    id: 16,
    name: "ニューバランスゴルフ（New Balance golf）NB 【抗菌防臭】サイドロゴミドルソックス",
    main: "ニューバランスゴルフ",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはロゴ刺繍、サイドにはロゴデザインを配置",
      "・足裏パイルで汗もしっかり吸収",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "2017年春夏シーズンからスタートしたニューバランスゴルフ。機能性を軸にファッション感覚を併せ持つ「SPORT」と、ファッショントレンド要素に機能面も兼備した「METRO」の２ラインで構成。ニューバランスのコンセプトや哲学を大切に、ファッションを意識した新たなゴルフスタイルを発信しています。",
    ],
    price: "1980",
    sizeImg: "/curu/size016.png",
    size: ["フリー", "フリー", "フリー"],
    color: ["ブラック", "グレー", "オフホワイト"],
    brand: "	ニューバランスゴルフ（New Balance golf）",
    catagory: "ミドルソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "0124286005",
    images: [
      "/curu/10016.jpg",
      "/curu/10016a.jpg",
      "/curu/10016b.jpg",
      "/curu/10016c.jpg",
      "/curu/10016d.jpg",
      "/curu/10016e.jpg",
      "/curu/10016f.jpg",
      "/curu/10016g.jpg",
    ],
  },
  {
    id: 17,
    name: "ニューエラ（NEW ERA）NE シンプルロゴボディバッグ",
    main: "ニューエラ",
    description: [
      "・ハリのあるツイル素材",
      "・フロントにはロゴタグを配置",
      "・カラビナ等を掛けられる外部のリング",
      "・小物の収納に便利なジップ付きインナーポケット",
      "・キーフックを内側に配備",
      "・ダブルジップ仕様",
      "・ショルダーは調節可能",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ニューヨーク州のバッファローで、紳士用カジュアルキャップやユニフォーム用キャップの製造からスタートした「NEW ERA(ニューエラ)」。",
      "世界最高峰と言われ、老舗となった今でも常に品質にこだわった「品質第一」主義を貫きとおし、数多くのファンを魅了し続けている。 中でもベースボールキャップは超有名。さまざまなスポーツやファッションに合わせ、数多くのアイテムを生み続けた「NEW ERA」は、今やタウンでも見かけるファッションアイテム。",
    ],
    price: "5500",
    sizeImg: "/curu/size017.png",
    size: ["フリー"],
    color: ["ブラック"],
    brand: "	ニューエラ（NEW ERA）",
    catagory: "	その他バッグ&ポーチ",
    shipDate: "当日 or 翌営業日出荷",
    returns: "可",
    itemNo: "11560427",
    images: [
      "/curu/10017.jpg",
      "/curu/10017a.jpg",
      "/curu/10017b.jpg",
      "/curu/10017c.jpg",
      "/curu/10017d.jpg",
      "/curu/10017e.jpg",
      "/curu/10017f.jpg",
      "/curu/10017g.jpg",
      "/curu/10017h.jpg",
      "/curu/10017i.jpg",
      "/curu/10017j.jpg",
    ],
  },
  {
    id: 18,
    name: "ニューエラ（NEW ERA）NE メッシュポケットショルダーバッグ",
    main: "ニューエラ",
    description: [
      "・ややハリのあるツイル素材",
      "・フロントにはロゴタグ、サイドにはロゴ刺繍を配置",
      "・便利な外側のジップ付きメッシュポケット",
      "・キーフックを内側に配備",
      "・背面にバッグ等への取り付けが可能なストラップを配備",
      "・取り外し可能なストラップでミニポーチやバッグインバッグとしても活躍",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ニューヨーク州のバッファローで、紳士用カジュアルキャップやユニフォーム用キャップの製造からスタートした「NEW ERA(ニューエラ)」。",
      "世界最高峰と言われ、老舗となった今でも常に品質にこだわった「品質第一」主義を貫きとおし、数多くのファンを魅了し続けている。 中でもベースボールキャップは超有名。さまざまなスポーツやファッションに合わせ、数多くのアイテムを生み続けた「NEW ERA」は、今やタウンでも見かけるファッションアイテム。",
    ],
    price: "3960",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック"],
    brand: "		ニューエラ（NEW ERA）",
    catagory: "	ショルダーバッグ",
    shipDate: "当日 or 翌営業日出荷",
    returns: " 可",
    itemNo: "12325642",
    images: [
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018b.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
      "/curu/10018g.jpg",
      "/curu/10018h.jpg",
      "/curu/10018i.jpg",
      "/curu/10018j.jpg",
    ],
  },
  {
    id: 19,
    name: "ニューエラ（NEW ERA）NE バイアスロゴシューズケース",
    main: "ニューエラ",
    description: [
      "・スポーツシューズの収納に特化したシューズケース",
      "・フロントには大きめにプリントされたロゴを配置",
      "・大きく開閉でき、シューズの出し入れがスムーズに",
      "・ダブルジップ仕様",
      "・軽量かつコンパクトなデザインで、簡易バッグとしても活用可能",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "ニューヨーク州のバッファローで、紳士用カジュアルキャップやユニフォーム用キャップの製造からスタートした「NEW ERA(ニューエラ)」。",
      "世界最高峰と言われ、老舗となった今でも常に品質にこだわった「品質第一」主義を貫きとおし、数多くのファンを魅了し続けている。 中でもベースボールキャップは超有名。さまざまなスポーツやファッションに合わせ、数多くのアイテムを生み続けた「NEW ERA」は、今やタウンでも見かけるファッションアイテム。",
    ],
    price: "2750",
    sizeImg: "/curu/size019.png",
    size: ["フリー"],
    color: ["ブラック"],
    brand: "	ニューエラ（NEW ERA）",
    catagory: "	シューズケース",
    shipDate: "当日 or 翌営業日出荷",
    returns: " 可",
    itemNo: "14108400",
    images: [
      "/curu/10019.jpg",
      "/curu/10019a.jpg",
      "/curu/10019b.jpg",
      "/curu/10019c.jpg",
      "/curu/10019d.jpg",
      "/curu/10019e.jpg",
      "/curu/10019f.jpg",
      "/curu/10019g.jpg",
      "/curu/10019h.jpg",
    ],
  },
  {
    id: 20,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "マーク＆ロナ",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "42900",
    preprice: "",
    off: "40%",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "ERR34100",
    images: [
      "/curu/10018b.jpg",
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
    ],
  },
  //下面是排名,有折扣和之前价格
  {
    id: 21,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "マーク＆ロナ",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "42900",
    preprice: "",
    off: "40%",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "ERR34100",
    images: [
      "/curu/10018b.jpg",
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
    ],
  },
  {
    id: 22,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "マーク＆ロナ-コード",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "49500",
    preprice: "",
    off: "40%",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "ERR34100",
    images: [
      "/curu/10018b.jpg",
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
    ],
  },
  {
    id: 23,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "ホーンガーメント",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "10560",
    preprice: "",
    off: "60%",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "ERR34100",
    images: [
      "/curu/10018b.jpg",
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
    ],
  },
  {
    id: 24,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "マーク＆ロナ",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "38280",
    preprice: "",
    off: "40%",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "ERR34100",
    images: [
      "/curu/10018b.jpg",
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
    ],
  },
  {
    id: 25,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "ヒグマゴルフストア",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "3850",
    preprice: "",
    off: "75%",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "ERR34100",
    images: [
      "/curu/10018b.jpg",
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
    ],
  },
  {
    id: 26,
    name: "ジュン＆ロぺ【吸水速乾】Rサークル刺繍ラメラインニーハイソックス",
    main: "パーリーゲイツ",
    description: [
      "・さらっとソフトな生地感",
      "・穿き口部分にはラメラインがあしらわれている",
      "・サイドにはロープ風に施されたロゴ刺繍を配置",
      "・程よいストレッチ性",
      "※お客様がお使いのモニターにより、実際の商品と色味が異なる場合がございます。",
      "レディースゴルフウェアブランドとして誕生した「JUN&ROPE’」。 ”FUN”ction GOLF～おしゃれをする楽しさ、高いパフォーマンスを支える機能性～をアイデンティティに、遊び心のあるポップなグラフィックやモードなシルエットに、スポーツウェアとして求められる、アスリートのための機能性を兼ねそなえたゴルフスタイルを発信しています。",
    ],
    price: "14520",
    preprice: "",
    off: "60%",
    sizeImg: "/curu/size018.png",
    size: ["フリー"],
    color: ["ブラック", "ライトグレー", "ネイビー"],
    brand: "	ジュン＆ロぺ",
    catagory: "ニーハイソックス",
    shipDate: "当日 or 翌営業日出荷",
    returns: "不可",
    itemNo: "ERR34100",
    images: [
      "/curu/10018b.jpg",
      "/curu/10018.jpg",
      "/curu/10018a.jpg",
      "/curu/10018c.jpg",
      "/curu/10018d.jpg",
      "/curu/10018e.jpg",
      "/curu/10018f.jpg",
    ],
  },
];

export default productData;
