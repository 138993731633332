import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="promo-banner-container">
          <div className="wrapper">
            <p>
              <img
                width="20"
                height="20"
                src="https://img.icons8.com/material-outlined/50/FFFFFF/suv-2.png"
                alt="suv-2"
              />
              &nbsp;今、購入すると 9/19(木) 出荷です
              ※ご決済完了の場合※一部の商品を除く
            </p>
          </div>
        </div>

        <div className="twoDiv">
          <img
            alt=""
            src="https://shop.curucuru.jp/m/higuma/img/article/240919/bnr_silver.jpg"
          />
          <img
            alt=""
            src="https://shop.curucuru.jp/m/higuma/img/article/240912/bnr_sqcat.jpg"
          />
          <img
            alt=""
            src="https://shop.curucuru.jp/m/higuma/img/article/240912/bnr_focus.jpg"
          />
        </div>

        {/* BRAND FOCUS */}
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center" style={{ marginBottom: "3%" }}>
                <h2 className="title2">BRAND FOCUS</h2>
              </div>

              <ul className="lineup_flex11">
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240523/outlet_pg.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-0-1620990567c819c487"
                  >
                    PEARLY GATES
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240704/bnr_sqbr1.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-1-1620990567c819c487"
                  >
                    NEW ERA
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/m/higuma/img/article/240620/outlet_ml.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    MARK & LONA
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/m/higuma/img/article/240814/bnr_gg.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    GREGORY
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/m/higuma/img/article/240905/outlet_nb.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    NEW BALANCE GOLF
                  </h3>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* 人気 */}
        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="title">
              <h2>
                <span>SPECIAL PRICE</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData
                  .filter((e) => e.id >= 1 && e.id <= 9)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>

                        <h2>{brand.main}</h2>
                        <div className="pp-pre">
                          <h4 className="red-price">
                            ￥{brand.price}(税込)&nbsp;
                            <small>({brand.off}Off)</small>
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center" style={{ margin: "2% 0" }}>
                <h2 className="title2">PICK UP ITEM</h2>
              </div>

              <ul className="lineup_flex">
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240912/bnr_focus.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    エネルギッシュなJリンドバーグに大注目！
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.09.12
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240905/bnr_focus.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    アクセントになるロゴアイテム！
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.09.12
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/m/higuma/img/article/240829/bnr_focus.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    ひとクセなアイテムを着こなせ！
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.09.12
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240523/bnr_higuma.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    HIGUMA GOLF STOREこだわりの別注・オリジナル
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.09.12
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/*今週のイチ推しブランド*/}
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center" style={{ marginBottom: "3%" }}>
                <h2 className="title2">NEW ITEM</h2>
              </div>

              <div>
                <div className="container-fluid">
                  {/* top */}
                  <div className="top-container">
                    <div className="product-grid">
                      {productData
                        .filter((e) => e.id >= 10 && e.id <= 19)
                        .map((brand) => (
                          <div className="product-category" key={brand.id}>
                            <div className="image-container">
                              <Link
                                to={`/shopDetails/${brand.id}`}
                                onClick={handleNavigate}
                              >
                                <img alt="" src={brand.images[0]} />
                              </Link>

                              <h2>{brand.main}</h2>
                              <div className="pp-pre">
                                <h4 className="red-price">¥{brand.price}</h4>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <h2 className="title2">FEATURE</h2>
              </div>

              <ul className="lineup_flex121">
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240912/bnr_sqcat.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    残暑も快適！軽めロングパンツ
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.09.12
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240725/bnr_sqcat1.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    真夏でも快適 半袖コレクション
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.07.25
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://shop.curucuru.jp/m/higuma/img/article/240718/bnr_summer.jpg"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    猛暑を乗り切れ！UV・暑さ対策グッズ
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.07.18
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240711/bnr_sqcat1.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    I♥短パン SHORT PANTS COLLECTION
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.07.11
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240627/bnr_sqcat1.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    夏の足元アップデート
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.06.27
                  </p>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/article/240620/bnr_sqcat1.jpg"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <p
                    style={{
                      width: "235px",
                      textAlign: "left",
                      marginTop: "1px",
                      color: "#272727",
                    }}
                  >
                    「おしゃれで快適」夏ヘッドウェアCOLLECTION
                  </p>
                  <p style={{ textAlign: "left", color: "#A0A0A0" }}>
                    2024.06.20
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="lineup_flex44">
                <h2 className="contents-ttl">BRAND</h2>
                <p className="more_link">ブランドをもっと見る＞</p>
                <ul>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/1834984_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/1822996_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/1856295_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/2506701_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/1856298_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/2016827_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/2380844_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/2472493_0.jpg?1726641558"
                    />
                  </li>
                  <li>
                    <img
                      alt=""
                      src="https://img11.shop-pro.jp/PA01297/346/category/2782813_0.jpg?1726641558"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_wrap121">
          <div className="footer_contents">
            <h2 className="contents-ttl">SHOP SERVICE</h2>
            <p className="more_link">ご利用ガイドをもっと見る＞</p>
            <div id="footer_info">
              <dl>
                <dt>
                  <p>
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/toppage/gift.svg"
                      alt=""
                    />
                    <span>無料ギフトラッピング</span>
                  </p>
                </dt>
                <dd>
                  無料でギフトラッピングをご用意。
                  <br />
                  大切な人への贈り物、コンペ賞品など
                  <br />
                  お気軽にご利用ください。
                </dd>
              </dl>
              <dl>
                <dt>
                  <p>
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/toppage/trac.svg"
                      alt=""
                    />
                    <span>送料・出荷について</span>
                  </p>
                </dt>
                <dd>
                  送料470円（北海道・沖縄・離島 900円）
                  <br />
                  15,000円以上のお買い上げで送料無料。
                  <br />
                  14時までのご注文で当日出荷致します。
                </dd>
              </dl>
              <dl>
                <dt>
                  <p>
                    <img
                      src="https://shop.curucuru.jp/m/higuma/img/toppage/help.svg"
                      alt=""
                    />
                    <span>お支払方法</span>
                  </p>
                </dt>
                <dd>
                  クレジットカード、代金引換、
                  <br />
                  銀行振込、コンビニ支払い、スマホ決済の
                  <br />
                  お支払い方法をご用意しております。
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
