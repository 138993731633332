import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("colours");
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);

  const handleAddToCart = () => {
    if (!selectedSize) {
      alert("Please select a size!");
      return;
    }
    if (!selectedColor) {
      alert("Please select a color!");
      return;
    }
    addToCart(
      product.id,
      quantities,
      selectedSize,
      selectedColor,
      product.cent
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleClick = (size) => {
    setSelectedSize(size);
  };
  const handleClick1 = (color) => {
    setSelectedColor(color);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
          </div>
          <div className="product-info">
            <h3 className="sldo__name">{product.name}</h3>
            <div className="prices sale">
              <p className="box__price">
                {product.preprice && (
                  <span className="origin_price">
                    ¥{product.preprice}
                    <span className="tax">(税込)</span>
                  </span>
                )}
                <span className="price">
                  ¥{product.price}
                  <span className="tax">(税込)</span>
                </span>
                {product.off && (
                  <span className="box__price--rate">({product.off} Off)</span>
                )}
              </p>
            </div>
            <div className="attention-wrapper">
              <p className="attention">
                【SALE品】この商品の返品・交換はお受けできません。
                <br />
                予めご了承下さい。
              </p>
            </div>

            <div className="product-caution">
              ■注意事項■
              <br />
              <br />
              ・こちらの商品は柄の位置が商品ごとに違い、写真と異なる場合がございます。柄位置の指定は出来かねますので、予めご了承ください。
            </div>

            <div className="delivery-outer">
              <div className="delivery">
                <img
                  width="20"
                  height="25"
                  src="https://img.icons8.com/ios-filled/50/1A1A1A/pickup.png"
                  alt="pickup"
                  style={{ marginRight: "7px", marginBottom: "-3px" }}
                />
                <span>今、購入すると</span>

                <span className="delivery-date">
                  <span className="delivery-date">9/19</span>
                  <span className="delivery-weekday">(木)</span>
                </span>

                <span className="delivery-ship">出荷</span>
                <span>です</span>
              </div>
              <div className="expl">※ご決済完了の場合。一部の商品を除く</div>
            </div>

            <div className="rio-product-option-title">サイズ</div>
            <div className="sldo__size_div">
              {product.size.map((size, index) => (
                <div
                  className={`size__item ${
                    selectedSize === size ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleClick(size)}
                >
                  {size}
                </div>
              ))}
            </div>
            <div
              className="rio-product-option-title"
              style={{ marginTop: "4%" }}
            >
              カラー
            </div>
            <div className="sldo__size_div">
              {product.color.map((color, index) => (
                <div
                  className={`size__item ${
                    selectedColor === color ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleClick1(color)}
                >
                  {color}
                </div>
              ))}
            </div>

            <input
              type="number"
              value={quantities}
              min="1"
              onChange={(e) =>
                setQuantities(Math.max(1, parseInt(e.target.value, 10)))
              }
              className="quantity-input"
            />

            <div className="btnDiv">
              <button className="add-to-cart" onClick={handleAddToCart}>
                <img
                  width="24"
                  height="24"
                  src="https://img.icons8.com/material-outlined/50/FFFFFF/shopping-bag.png"
                  alt="shopping-bag"
                />
                カートに入れる
              </button>
            </div>
            <div className="item-des-color">
              <div className="tab-set__tab-group">
                <div className="tab-set__tab-window">
                  <div
                    className={`tab-set__tab ${
                      activeTab === "description" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("description")}
                  >
                    サイズ・素材
                  </div>
                  <div
                    className={`tab-set__tab ${
                      activeTab === "colours" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("colours")}
                  >
                    アイテム説明
                  </div>
                </div>
              </div>
              <div className="tab-set__body-group">
                <div
                  className={`tab-set__body ${
                    activeTab === "description" ? "is-active" : ""
                  }`}
                >
                  <img alt="" src={product.sizeImg} />
                </div>
                <div
                  className={`colors-item ${
                    activeTab === "colours" ? "is-active" : ""
                  }`}
                >
                  <ul className="describe__ul">
                    {product.description.map((description, index) => (
                      <li key={index}>{description}</li>
                    ))}
                  </ul>
                  <div className="description-base_info_table_wrapper">
                    <table className="description-base_info_table">
                      <tr>
                        <td>ブランド</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>
                          <font _mstmutation="1">{product.brand}</font>
                        </td>
                      </tr>
                      <tr>
                        <td>カテゴリ</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>
                          <font _mstmutation="1">{product.catagory}</font>
                        </td>
                      </tr>
                      <tr>
                        <td>出荷日</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>{product.shipDate}</td>
                      </tr>
                      <tr>
                        <td>返品交換</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>{product.returns}</td>
                      </tr>
                      <tr>
                        <td>品番</td>
                        <td style={{ width: "3%" }}>：</td>
                        <td>{product.itemNo}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
