import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="haul_img">
          <img
            alt=""
            src="https://shop.curucuru.jp/m/higuma/img/footer_logo.png"
          />
        </div>
        <p className="haul_txt">
          【休業日】日曜日
          <br />
          休業日も24時間ご注文可能ですが
          <br />
          メール・発送対応は休み明けとなります。
        </p>
        <ul className="footer_link">
          <li class="snslink">
            <img
              width="35"
              height="35"
              src="https://img.icons8.com/ios-glyphs/50/FFFFFF/facebook-new.png"
              alt="facebook-new"
            />
            <span>Facebook</span>
          </li>
          <li class="snslink">
            <img
              width="35"
              height="35"
              src="https://img.icons8.com/windows/50/FFFFFF/instagram-new.png"
              alt="instagram-new"
            />
            <span>Instagram</span>
          </li>
          <li class="snslink">
            <img
              width="35"
              height="35"
              src="https://img.icons8.com/ios/50/FFFFFF/chat-message--v1.png"
              alt="chat-message--v1"
            />
            <span>LINE</span>
          </li>
          <li class="snslink">
            <svg
              width="27"
              height="28"
              viewBox="0 0 27 28"
              fill="none"
              aria-label="X(Twitter)"
            >
              <g clip-path="url(#clip0_1_18)">
                <path
                  d="M16.0687 11.7356L26.12 0H23.7382L15.0106 10.1899L8.03988 0H0L10.5411 15.4089L0 27.7155H2.38199L11.5985 16.9546L18.9601 27.7155H27L16.0681 11.7356H16.0687ZM12.8062 15.5447L11.7382 14.0103L3.24025 1.80106H6.89884L13.7568 11.6543L14.8248 13.1887L23.7393 25.9963H20.0807L12.8062 15.5452V15.5447Z"
                  fill="currentColor"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1_18">
                  <rect width="27" height="27.7297" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <span>X</span>
          </li>
        </ul>
      </div>
      <div className="botLeft">
        <div className="comImfo">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                ホーム
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                お問い合わせ
              </Link>
            </li>
            <li>
              <Link to="/terms" onClick={toTop}>
                利用規約
              </Link>
            </li>
            <li>
              <Link to="/privacy" onClick={toTop}>
                個人情報保護方針
              </Link>
            </li>
            <li>
              <Link to="/particular" onClick={toTop}>
                特定商取引法・古物営業法・酒税金に基づく表示
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="botLeft" style={{ padding: "14px 0" }}>
        <div className="comImfo">
          <p>Copyright © HIGUMA GOLF STORE All Rights Reserved.</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
